<template>
    <div class="about">
        <div class="page-head contact">
            <h1 class="page-title font-weight-bold text-uppercase">
                {{ $t('contact') }}
            </h1>
        </div>
        <div class="page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 order-2 order-lg-1">
                        <h3 class="font-weight-600 text-uppercase mb-4">
                            {{ $t('contactUs') }}
                        </h3>
                        <form @submit.prevent="sendForm" class="contact-form">
                            <div class="col-12" v-for="(error, index) in errors" :key="index">
                                <b-alert class="p-2" show variant="danger">{{ error }}</b-alert>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            class="
                                                d-flex
                                                align-items-center
                                                mx-3
                                            "
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 10.524 10.524"
                                                >
                                                    <path
                                                        d="M5.262,5.92A2.96,2.96,0,1,0,2.3,2.96,2.961,2.961,0,0,0,5.262,5.92Zm2.631.658H6.761a3.578,3.578,0,0,1-3,0H2.631A2.631,2.631,0,0,0,0,9.209v.329a.987.987,0,0,0,.987.987H9.538a.987.987,0,0,0,.987-.987V9.209A2.631,2.631,0,0,0,7.893,6.578Z"
                                                        fill="#007ec6"
                                                    />
                                                </svg>
                                            </span>
                                            <span class="mx-2 pt-1"
                                                >{{ $t('yourname') }}</span
                                            >
                                        </label>
                                        <input
                                            v-model.trim="name"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('name')"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            class="
                                                d-flex
                                                align-items-center
                                                mx-3
                                            "
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 9.823 10.524"
                                                >
                                                    <path
                                                        d="M7.5,6V8.1h3.859v8.419h2.1V8.1h3.859V6Z"
                                                        transform="translate(-7.5 -6)"
                                                        fill="#007ec6"
                                                    />
                                                </svg>
                                            </span>
                                            <span class="mx-2 pt-1"
                                                >{{ $t('msgTitle') }}</span
                                            >
                                        </label>
                                        <input
                                            v-model.trim="title"
                                            type="text"
                                            class="form-control"
                                            :placeholder="$t('msgTitle')"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <div>
                                        <div class="form-group">
                                            <label
                                                class="
                                                    d-flex
                                                    align-items-center
                                                    mx-3
                                                "
                                            >
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="21"
                                                        height="18"
                                                        viewBox="0 0 15.591 10.524"
                                                    >
                                                        <path
                                                            d="M.072,13.66V5q0-.015.045-.286l5.1,4.36L.132,13.961a1.274,1.274,0,0,1-.06-.3ZM.749,4.113A.648.648,0,0,1,1,4.068H14.731A.851.851,0,0,1,15,4.113L9.89,8.488l-.677.541-1.338,1.1-1.338-1.1L5.86,8.488ZM.764,14.547,5.89,9.631l1.985,1.609L9.859,9.631l5.127,4.916a.722.722,0,0,1-.256.045H1a.681.681,0,0,1-.241-.045Zm9.772-5.473,5.082-4.36A.9.9,0,0,1,15.663,5v8.66a1.152,1.152,0,0,1-.045.3Z"
                                                            transform="translate(-0.072 -4.068)"
                                                            fill="#007ec6"
                                                        />
                                                    </svg>
                                                </span>
                                                <span class="mx-2 pt-1"
                                                    >{{ $t('yourEmail') }}</span
                                                >
                                            </label>
                                            <input
                                                v-model.trim="email"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('email')"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="form-group">
                                            <label
                                                class="
                                                    d-flex
                                                    align-items-center
                                                    mx-3
                                                "
                                            >
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="19"
                                                        height="19"
                                                        viewBox="0 0 10.524 10.524"
                                                    >
                                                        <path
                                                            d="M10.224,7.437l-2.3-.987a.493.493,0,0,0-.576.142L6.327,7.838A7.619,7.619,0,0,1,2.685,4.2L3.93,3.176A.492.492,0,0,0,4.072,2.6L3.085.3A.5.5,0,0,0,2.52.013L.382.506A.493.493,0,0,0,0,.987a9.537,9.537,0,0,0,9.538,9.538.493.493,0,0,0,.481-.382L10.512,8a.5.5,0,0,0-.288-.567Z"
                                                            transform="translate(0 0)"
                                                            fill="#007ec6"
                                                        />
                                                    </svg>
                                                </span>
                                                <span class="mx-2 pt-1"
                                                    >{{ $t('yourMobile') }}</span
                                                >
                                            </label>
                                            <input
                                                v-model.trim="mobile"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('mobile')"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <div class="form-group">
                                        <label
                                            class="
                                                d-flex
                                                align-items-center
                                                mx-3
                                            "
                                        >
                                            <span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 10.762 10.762"
                                                >
                                                    <path
                                                        d="M12.686,3H4.076A1.075,1.075,0,0,0,3.005,4.076L3,13.762,5.152,11.61h7.533a1.079,1.079,0,0,0,1.076-1.076V4.076A1.079,1.079,0,0,0,12.686,3ZM11.61,9.457H5.152V8.381H11.61Zm0-1.614H5.152V6.767H11.61Zm0-1.614H5.152V5.152H11.61Z"
                                                        transform="translate(-3 -3)"
                                                        fill="#007ec6"
                                                    />
                                                </svg>
                                            </span>
                                            <span class="mx-2"
                                                >{{ $t('yourMsg') }}</span
                                            >
                                        </label>
                                        <textarea
                                            v-model.trim="body"
                                            class="form-control"
                                            :placeholder="$t('msg')"
                                            rows="6"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button
                                        type="submit"
                                        class="
                                            btn btn-quiz
                                            text-uppercase
                                            mx-auto
                                        "
                                    >
                                        <b-spinner
                                            v-if="sending"
                                            variant="light"
                                        ></b-spinner>
                                        <span v-else class="text-white"
                                            >{{ $t('sendMsg') }}</span
                                        >
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-4 order-1 order-lg-2 mb-4 mb-lg-0">
                        <div class="d-flex">
                            <div
                                class="contacts-seperator d-none d-lg-block"
                            ></div>
                            <div>
                                <h3
                                    class="
                                        font-weight-600
                                        text-uppercase
                                        mb-3 mb-lg-5
                                    "
                                >
                                    {{ $t('contactInfo') }}
                                </h3>
                                <ul class="contacts">
                                    <li v-if="contacts.telephone" class="d-flex align-items-start">
                                        <span
                                            class="
                                                icon
                                                bg-brand
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 15.542 15.542"
                                            >
                                                <path
                                                    d="M14.976.746,11.82.018a.733.733,0,0,0-.835.422l-1.457,3.4a.727.727,0,0,0,.209.85l1.839,1.506A11.25,11.25,0,0,1,6.2,11.574L4.692,9.734a.728.728,0,0,0-.85-.209l-3.4,1.457a.737.737,0,0,0-.425.838l.729,3.157a.728.728,0,0,0,.71.565A14.083,14.083,0,0,0,15.541,1.457.728.728,0,0,0,14.976.746Z"
                                                    transform="translate(0.001 0.001)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </span>
                                        <a :href="`tel:${contacts.telephone}`" class="mx-3">
                                            {{ contacts.telephone }}
                                        </a>
                                    </li>
                                    <li v-if="contacts.mobile" class="d-flex align-items-start">
                                        <span
                                            class="
                                                icon
                                                bg-brand
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 10.803 17.285"
                                            >
                                                <path
                                                    d="M9.183,0H1.62A1.621,1.621,0,0,0,0,1.62V15.665a1.621,1.621,0,0,0,1.62,1.62H9.183a1.621,1.621,0,0,0,1.62-1.62V1.62A1.621,1.621,0,0,0,9.183,0ZM5.4,16.2a1.08,1.08,0,1,1,1.08-1.08A1.079,1.079,0,0,1,5.4,16.2Z"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </span>
                                        <a :href="`tel:${contacts.mobile}`" class="mx-3">
                                            {{ contacts.mobile }}
                                        </a>
                                    </li>
                                    <li v-if="contacts.site" class="d-flex align-items-start">
                                        <span
                                            class="
                                                icon
                                                bg-brand
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 15.542 15.542"
                                            >
                                                <path
                                                    d="M10.763,3a7.771,7.771,0,1,0,7.779,7.771A7.767,7.767,0,0,0,10.763,3Zm5.385,4.663H13.856A12.161,12.161,0,0,0,12.784,4.9,6.24,6.24,0,0,1,16.148,7.663ZM10.771,4.585a10.947,10.947,0,0,1,1.484,3.077H9.287A10.947,10.947,0,0,1,10.771,4.585Zm-6.015,7.74a6.079,6.079,0,0,1,0-3.108H7.383a12.834,12.834,0,0,0-.109,1.554,12.834,12.834,0,0,0,.109,1.554Zm.637,1.554H7.686a12.161,12.161,0,0,0,1.072,2.766A6.207,6.207,0,0,1,5.393,13.879ZM7.686,7.663H5.393A6.207,6.207,0,0,1,8.758,4.9,12.161,12.161,0,0,0,7.686,7.663Zm3.085,9.294a10.947,10.947,0,0,1-1.484-3.077h2.969A10.947,10.947,0,0,1,10.771,16.957Zm1.818-4.631H8.953a11.433,11.433,0,0,1-.124-1.554,11.334,11.334,0,0,1,.124-1.554h3.637a11.334,11.334,0,0,1,.124,1.554A11.433,11.433,0,0,1,12.589,12.325Zm.194,4.321a12.161,12.161,0,0,0,1.072-2.766h2.292a6.24,6.24,0,0,1-3.365,2.766Zm1.375-4.321a12.834,12.834,0,0,0,.109-1.554,12.834,12.834,0,0,0-.109-1.554h2.627a6.079,6.079,0,0,1,0,3.108Z"
                                                    transform="translate(-3 -3)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </span>
                                        <a :href="contacts.site" target="_blank" class="mx-3">
                                        {{ contacts.site }}
                                        </a>
                                    </li>
                                    <li v-if="contacts.address"
                                        class="
                                            d-flex
                                            align-items-start
                                            justify-content-start
                                        "
                                    >
                                        <span
                                            class="
                                                icon
                                                bg-brand
                                                d-flex
                                                justify-content-center
                                                align-items-center
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 10.803 15.433"
                                            >
                                                <path
                                                    d="M12.9,3A5.4,5.4,0,0,0,7.5,8.4c0,4.051,5.4,10.032,5.4,10.032s5.4-5.98,5.4-10.032A5.4,5.4,0,0,0,12.9,3Zm0,7.331A1.929,1.929,0,1,1,14.831,8.4,1.93,1.93,0,0,1,12.9,10.331Z"
                                                    transform="translate(-7.5 -3)"
                                                    fill="#fff"
                                                />
                                            </svg>
                                        </span>
                                        <pre class="mx-3 address">
                                            {{ contacts.address }}
                                        </pre>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import TopFooterAds from "@/components/TopFooterAds";
import { sendContactForm } from "@/API/contact";

export default {
    name: "ContactUs",
    components: {
        TopFooterAds,
    },
    data() {
        return {
            sending: false,
            name: null,
            email: null,
            mobile: null,
            title: null,
            body: null,
            errors: []
        };
    },
    computed: {
        contacts() {
            return this.$store.state.contacts || {}
        }
    },
    methods: {
        validateInputs() {
            if (!this.name) {
                this.errors.push(this.$t("nameRequired"));
                return false;
            }
            if (!this.title) {
                this.errors.push(this.$t("titleRequired"));
                return false;
            }
            if (!this.validateEmail()) {
                this.errors.push(this.$t("validEmailError"));
                return false;
            }
            if (!this.mobile) {
                this.errors.push(this.$t("mobileRequired"));
                return false;
            }
            if (!this.body) {
                this.errors.push(this.$t("bodyRequired"));
                return false;
            }

            return true;
        },
        validateEmail() {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isValidEmail = re.test(String(this.email).toLowerCase());
            return isValidEmail;
        },
        sendForm() {
            this.errors = [];
            if (!this.validateInputs()) {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                return;
            }

            this.sending = true;
            
            const form = {
                name: this.name,
                email: this.email,
                mobile: this.mobile,
                message_title: this.title,
                message_body: this.body
            }
            sendContactForm(form).then((res) => {
                // console.log(res);
                this.sending = false;
                if (res.status == 200) {
                    // console.log(res.data);
                    this.$notify(res.data.message);
                    this.name = null;
                    this.email = null;
                    this.mobile = null;
                    this.title = null;
                    this.body = null;
                    
                    return;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res.response?.data?.message) {
                    this.errors.push(res.response.data.message);
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        }
    }
};
</script>
